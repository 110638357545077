import React from "react";
import { Row, Col } from "react-bootstrap";

const OtomotifPrizeTab = () => {
  return (
    <div className="tab-contest px-4 pb-4">
      <Row>
        <Col md={12}>
          <h1 className="mt-4 first-main-content">HADIAH LOMBA</h1>
          <h3 className="first-main-content">PHOTO CONTEST</h3>

          <div className="mt-4 lomba-otomotif-txt">
            Hadiah khusus untuk peserta yang submit foto dengan{" "}
            <span className="otomotif-txt">Genre Otomotif.</span>
          </div>

          <div className="d-flex align-items-center mt-4">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/zve10.webp"
                alt="logo-sony"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 1</div>
              <div className="first-main-content-txt-bold-3">SONY ZV-E10</div>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/ad100pro.webp"
                alt="logo-ad100p"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 2</div>
              <div className="first-main-content-txt-bold-3 d-flex flex-wrap">
                <div>GODOX AD100Pro</div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/tl60.webp"
                alt="logo-ad100p"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 3</div>
              <div className="first-main-content-txt-bold-3 d-flex flex-wrap">
                <div>GODOX TL60</div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center juara-favorit mt-3 w-100">
            <div className="price-img-container-2 mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg-2"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/voucher-doss.webp"
                alt="logo-ad100p"
                className="price-img-content-2"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold-4">
                Juara Pilihan Favorit Main Sponsor
              </div>
              <div className="first-main-content-txt-bold-2 d-flex">
                <div className="d-flex mx-2 flex-column align-items-center justify-content-center">
                  <div className="voucher-txt">Voucher</div>
                  <div className="doss-txt">DOSS</div>
                </div>{" "}
                <div>5.000.000</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OtomotifPrizeTab;
