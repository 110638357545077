import React from "react";
import { Row, Col } from "react-bootstrap";

const EditingRuleTab = () => {
  return (
    <div className="tab-contest px-4 pb-4">
      {/* TATA TERTIB LOMBA EDITING */}
      <Row id="lomba">
        <Col md={12}>
          <h3 className="mt-4 first-main-content-2">
            TATA TERTIB LOMBA EDITING
          </h3>
          <div className="mt-4 first-main-content-p">
            1. Lomba editing dapat di edit sekreatif mungkin tanpa batas. <br />
            2. Registrasi keikutsertaan kamu ke idcc.doss.co.id lalu ikuti
            petunjuk untuk proses download foto RAW dan upload foto yang telah
            di edit dan ingin diikutsertakan untuk kompetisi.
            <br />
            3. Download Foto (RAW/JPEG) di link yang telah disediakan diatas.{" "}
            <br />
            4. Edit foto yang telah kamu download seunik mungkin, sekreatif, dan
            sebebas - bebasnya.
            <br />
            5. Unggah foto yang kamu edit ke sosial media Instagram
            masing-masing dengan format caption:
            <br />
            <blockquote className="quote-rules">
              Kontes foto dan kontes editing terbesar di Indonesia dengan total
              hadiah 100 Juta Rupiah. <br />
              IDCC 2021 berkolaborasi dengan : <br />
              -------------------------------- <br />
              @idcc_id @caffeine.id @loveyuasa @doss.id @lensacommunity @gump.gg{" "}
              <br />
              - <br />
              - <br />
              - <br />
              - <br />
              - <br />
              Fotografer : (tag) <br />
              Komunitas : (tag) <br />
              Model : (tag) <br />
              Editor : (tag) <br />
              - <br />
              - <br />
              - <br />
              - <br />
              - <br />
              Yuk jangan sampai ketinggalan (tag 3 temanmu) <br />
              #IDCC2021 #IDCC2021editingcompetition <br />
              #IndonesiaCreativeCompetition <br />
              #caffeineid <br />
              #LoveYuasa #LetsRockYourBattery #dossid <br />
              #dosscommunity #LensaCommunity <br />
              #gumpgg <br />
            </blockquote>
            6. Follow dan tag @idcc_id @cafeine.id @loveyuasa @doss.id
            @lensacommunity @gump.gg
            <br />
            7. Share postingan kalian dan ajak teman kalian sebanyak-banyaknya
            untuk ikut event ini. <br />
            8. Akun Instagram tidak boleh di private (harus public). <br />
            9. Hasil editan adalah hasil karya sendiri. <br />
            10. Foto yang diedit dan diikutsertakan belum pernah memenangkan
            lomba sebelumnya. <br />
            11. Edit foto sebanyak yang kamu suka. <br />
            12. Periode lomba 3 Desember 2021 - 31 Maret 2022. <br />
            13. Dewan juri akan melakukan penilaian dari editing berdasarkan
            kreatifitas, warna dan detail editan. <br />
            14. Pemenang diputuskan oleh dewan juri, dan keputusan dewan juri
            adalah mutlak. <br />
            15. Hasil karya foto peserta menjadi hak milik penyelenggara.
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EditingRuleTab;
