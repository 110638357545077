import React from "react";
import { Modal, Container, Row } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MdClose } from "react-icons/md";

const ModalZoomImage = ({ show, handleClose, image }) => {
  return (
    <Modal
      // dialogClassName="modal-90w"
      centered
      show={show}
      onHide={handleClose}
    >
      <Container fluid className="position-relative">
        <MdClose
          size={20}
          color="#FFFF"
          className="close-btn-zoom"
          onClick={handleClose}
        />
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={200}
            defaultPositionY={100}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <TransformComponent>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={image}
                    alt="test"
                  />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalZoomImage;
