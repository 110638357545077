import React from "react";
import { Row, Col } from "react-bootstrap";
import { TiGroup } from "react-icons/ti";
import { AiFillCamera } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { MdCloudDownload } from "react-icons/md";

const EditingGalleryTab = ({
  photos,
  isLogin,
  openLogin,
  openZoom,
  setIndexImg,
}) => {
  const convertStrip = (string) => {
    // const changed = string.replaceAll("_", "-");
    const changed = string.replace(/_/g, "-");
    return changed;
  };

  return (
    <div className="tab-contest px-4 pb-4">
      {/* GALLERY EDITING CONTEST */}
      {!isLogin && (
        <div className="no-login-container">
          <div
            className="contest-desc-txt"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            KAMU HARUS LOGIN UNTUK DOWNLOAD FILE
          </div>
          <div
            className="button-yellow mt-3"
            style={{ width: "200px" }}
            onClick={openLogin}
          >
            LOGIN
          </div>
        </div>
      )}
      {isLogin && (
        <Row>
          <Col md={12}>
            <h3 className="mt-4 first-main-content-2">GALLERY</h3>
            <h5 className="first-main-content-2">EDITING CONTEST</h5>
            <div className="mt-4 first-main-content-p gallery-container">
              {photos.map((photo, index) => (
                <div key={index} style={{ overflow: "hidden" }}>
                  <div
                    className="gallery-img"
                    onClick={() => {
                      setIndexImg(index);
                      openZoom();
                    }}
                  >
                    <img
                      src={`https://main.mobile.doss.co.id/storage/uploads/2021/12/medium/${convertStrip(
                        photo.thumburl
                      )}`}
                      alt={photo.photographer}
                    />
                  </div>

                  <div className="mt-1 pg-txt d-flex align-items-center">
                    <TiGroup size={16} color="#FFF" className="mr-1" />
                    <div
                      onClick={() =>
                        window.open(
                          `https://www.instagram.com/${photo.community}/`
                        )
                      }
                      className="hover-txt"
                    >
                      {photo.community}
                    </div>
                  </div>

                  <div className="pg-txt d-flex justify-content-start align-items-center">
                    <AiFillCamera size={16} color="#FFF" className="mr-1" />
                    <div
                      onClick={() =>
                        window.open(
                          `https://www.instagram.com/${photo.photographer}/`
                        )
                      }
                      className="hover-txt"
                    >
                      {photo.photographer}
                    </div>
                  </div>

                  <div className="model-txt d-flex align-items-center">
                    <BsFillPersonFill size={16} color="#FFF" className="mr-1" />
                    <div
                      onClick={() =>
                        window.open(`https://www.instagram.com/${photo.model}/`)
                      }
                      className="hover-txt"
                    >
                      {photo.model ? photo.model : "-"}
                    </div>
                  </div>
                  <div className="mt-2 model-txt d-flex align-items-center justify-content-center download-btn-gallery">
                    <MdCloudDownload size={16} color="#FFF" className="mr-1" />
                    {photo.rawurl !== "#N/A" && (
                      <>
                        <div
                          className="download-btn"
                          onClick={() => window.open(photo.rawurl)}
                        >
                          RAW
                        </div>{" "}
                        /{" "}
                      </>
                    )}
                    <div
                      className="download-btn"
                      onClick={() => window.open(photo.jpgurl)}
                    >
                      JPG
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EditingGalleryTab;
