import React, { useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { AiFillGoogleCircle } from "react-icons/ai";
import GoogleLogin from "react-google-login";
import { useFormik } from "formik";
import * as yup from "yup";

import { API, setAuthToken } from "../configs/API";

const ModalLogin = ({
  show,
  handleClose,
  referralCode,
  setIsLogin,
  toRegister,
  setUserInfo,
  openOTP,
  openForgot,
  setOTPType,
  openPhone,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string("Masukkan email anda")
      .email("Masukkan format email yang benar")
      .required("Email dibutuhkan"),
    password: yup
      .string("Masukkan password anda")
      .min(6, "Password harus minimal 6 karakter")
      .required("Password dibutuhkan"),
    referral_code: yup.string(""),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      referral_code: referralCode,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmitLogin(values);
    },
  });

  const handleSubmitLogin = async (body) => {
    try {
      setIsLoading(true);

      const resCheck = await API.get(`auth/steps?email=${body.email}`);

      if (resCheck.data.data.steps.PHONE_VERIFIED === false) {
        setIsLoading(false);
        handleClose();
        openPhone();
      } else {
        const res = await API.post("auth/login", body);

        if (res.data.error == 401) {
          alert("Password yang anda masukan salah");
        } else if (res.data.error == 36) {
          localStorage.setItem("email", body.email);
          alert("Mohon masukan kode verifikasi dari email kamu");
          const res2 = await API.post("auth/otp/email/resend", {
            email: body.email,
          });

          handleClose();
          openOTP();
          resetFormik();
        } else if (res.data.error == 422) {
          alert("Email yang anda masukan belum terdaftar");
        } else {
          setAuthToken(res.data.data.access_token.token);
          const res2 = await API.get("info/account?referral_code=GJIYNM0H");

          setIsLogin(true);

          localStorage.setItem("token", res.data.data.access_token.token);
          setUserInfo(res.data.data.user);

          resetFormik();

          handleClose();

          window.location.href = `https://idcc.doss.co.id?code=${res2.data.data.code}`;
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert(error);
    }
  };

  const loginGoogle = async (response) => {
    try {
      const res = await API.post("auth/social/google", {
        id_token: response.tokenId,
      });

      if (res.data.data.user.phone == null) {
        // dispatch({
        //   type: "SET_PHONE",
        //   payload: true,
        // });
      }

      if (res.data.data.user.activated_at == null) {
        alert("Mohon masukan kode OTP di dalam inbox email kamu");
        localStorage.setItem("email", response.profileObj.email);

        handleClose();
        openOTP();
      }

      if (res.data.error == 0) {
        setAuthToken(res.data.data.access_token.token);
        localStorage.setItem("token", res.data.data.access_token.token);

        const res2 = await API.get("info/account?referral_code=GJIYNM0H");

        setIsLogin(true);
        setUserInfo(res.data.data.user);

        handleClose();

        window.location.href = `https://idcc.doss.co.id?code=${res2.data.data.code}`;
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const handleForgot = () => {
    handleClose();
    openForgot();
  };

  const resetFormik = () => {
    formik.setValues({ email: "", password: "", referral_code: "" });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body style={{ padding: "30px" }}>
        <div className="d-flex justify-content-between mb-4">
          <div className="modal-header-txt">MASUK</div>
          <MdClose
            color="#FFF"
            size={24}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{ color: "white" }}>Alamat Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Masukan email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="">
            <Form.Label style={{ color: "white" }}>Kata Sandi</Form.Label>
            <Form.Control
              type="password"
              placeholder="Masukan kata sandi"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <div className="mb-4 forgot-pass-txt" onClick={() => handleForgot()}>
            Lupa password?
          </div>

          <Button
            type="submit"
            className="main-btn"
            style={{ width: "100%" }}
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="mr-2"
              />
            )}
            {!isLoading && <span>Masuk</span>}
          </Button>

          {/* <div className="or-text mt-2">Atau</div> */}

          {/* <GoogleLogin
            clientId="563618122313-1a6m095n2filthn65a7g1e8rk26i607t.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                variant="contained"
                fullWidth
                className="mb-2 btn-google"
              >
                <AiFillGoogleCircle size={22} className="mr-2" />
                Masuk dengan Google
              </Button>
            )}
            onSuccess={loginGoogle}
            // onFailure={loginGoogle}
            cookiePolicy={"single_host_origin"}
          /> */}

          <div
            style={{
              textAlign: "center",
              fontSize: "80%",
              color: "#FFF",
            }}
            className="mt-3"
          >
            Belum punya akun DOSS? Klik{" "}
            <span
              className="here-text"
              onClick={() => {
                resetFormik();
                toRegister();
              }}
            >
              disini
            </span>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalLogin;
