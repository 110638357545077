import axios from "axios";

export const API = axios.create({
  baseURL: "https://api.mobile.doss.co.id/api/v1/",
});

export const APIIDCC = axios.create({
  baseURL: "https://idcc.doss.co.id/r/",
});

// export const LIVE = "http://localhost:5000/";
export const LIVE = "https://doss.co.id/";

export const setAuthToken = (token) => {
  if (token) {
    API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    APIIDCC.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete API.defaults.headers.common["Authorization"];
    delete APIIDCC.defaults.headers.common["Authorization"];
  }
};
