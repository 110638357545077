import React from "react";
import { Row, Col } from "react-bootstrap";

const PhotoPrizeTab = () => {
  return (
    <div className="tab-contest px-4 pb-4">
      <Row>
        <Col md={12}>
          <h1 className="mt-4 first-main-content">HADIAH LOMBA</h1>
          <h3 className="first-main-content">PHOTO CONTEST</h3>

          <div className="d-flex align-items-center mt-4">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/a7iv.webp"
                alt="logo-sony"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 1</div>
              <div className="first-main-content-txt-bold-3">
                SONY ALPHA A7IV
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/sz150r.webp"
                alt="logo-ad100p"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 2</div>
              <div className="first-main-content-txt-bold-3 d-flex flex-wrap">
                <div>GODOX SZ150R</div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/sl200ii.webp"
                alt="logo-ad100p"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 3</div>
              <div className="first-main-content-txt-bold-3 d-flex flex-wrap">
                <div>GODOX SL200 II</div>
              </div>
            </div>
          </div>

          <Row className="">
            <Col md={6}>
              <div className="d-flex align-items-center juara-4 mt-3 w-100">
                <div className="price-img-container-2 mr-2">
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                    alt="bg-price"
                    className="price-img-bg-2"
                  />
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/ad100pro.webp"
                    alt="logo-ad100p"
                    className="price-img-content-2"
                  />
                </div>
                <div>
                  <div className="first-main-content-txt-bold-4">Juara 4</div>
                  <div className="first-main-content-txt-bold-5">
                    GODOX AD100Pro
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex align-items-center mt-3 w-100">
                <div className="price-img-container-2 mr-2">
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                    alt="bg-price"
                    className="price-img-bg-2"
                  />
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/m1.webp"
                    alt="logo-ad100p"
                    className="price-img-content-2"
                  />
                </div>
                <div>
                  <div className="first-main-content-txt-bold-4">Juara 5</div>
                  <div className="first-main-content-txt-bold-5">GODOX M1</div>
                  <div className="first-main-content-txt-bold-5 d-flex">
                    <div>+</div>
                    <div className="d-flex mx-2 flex-column align-items-center justify-content-center">
                      <div className="voucher-txt">Voucher</div>
                      <div className="doss-txt">DOSS</div>
                    </div>
                    <div>Rp 500.000</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="d-flex align-items-center juara-favorit mt-3 w-100">
            <div className="price-img-container-2 mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg-2"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/voucher-doss.webp"
                alt="logo-ad100p"
                className="price-img-content-2"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold-4">
                Juara Favorit 1-5
              </div>
              <div className="first-main-content-txt-bold-2 d-flex">
                <div className="d-flex mx-2 flex-column align-items-center justify-content-center">
                  <div className="voucher-txt">Voucher</div>
                  <div className="doss-txt">DOSS</div>
                </div>{" "}
                <div>Rp 500.000</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PhotoPrizeTab;
