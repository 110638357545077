import React from "react";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { IoMdClose } from "react-icons/io";

// Asset
// import BGImage from "../assets/images/bg-sidebar-webp.webp";
// import IDDCLogo from "../assets/images/idcc-white-webp.webp";

const Sidebar = ({
  collapsed,
  handleCollapsed,
  activeMenu,
  onPressLogin,
  onPressRegister,
  goToAcara,
  goToHadiah,
  goToLomba,
  goToTentang,
  isLogin,
  logout,
  userInfo,
  isJoined,
  handleJoin,
}) => {
  return (
    <>
      <ProSidebar
        collapsed={collapsed}
        breakPoint="md"
        collapsedWidth="0px"
        rtl={true}
        image="https://main.mobile.doss.co.id/storage/uploads/2021/11/bg-sidebar-webp.webp"
        className="position-fixed"
        style={{ right: 0, left: "auto" }}
      >
        <SidebarHeader>
          <div className="p-3 d-flex justify-content-between align-items-center">
            <IoMdClose
              size={25}
              className="aside-close-btn mt-1 ml-2"
              onClick={() => handleCollapsed(true)}
            />
            <img
              src="https://main.mobile.doss.co.id/storage/uploads/2021/11/idcc-white-webp%20%281%29.webp"
              alt="logo-iddc"
              className="sidebar-logo"
            />
          </div>
        </SidebarHeader>

        <SidebarContent className="position-relative">
          {isLogin && (
            <div
              className="logout-btn"
              onClick={() => {
                logout();
                handleCollapsed(true);
              }}
            >
              Logout
            </div>
          )}

          {!isLogin && (
            <div className="p-3 my-4 d-flex flex-nowrap justify-content-around align-items-center">
              <div className="sidebar-top-btn" onClick={onPressLogin}>
                MASUK
              </div>
              <div>|</div>
              <div className="sidebar-top-btn" onClick={onPressRegister}>
                DAFTAR
              </div>
            </div>
          )}

          {isLogin && (
            <div className="p-3 d-flex flex-column flex-nowrap justify-content-center align-items-center">
              <div className="account-img-container">
                <img
                  src={
                    userInfo
                      ? userInfo.avatar
                      : "https://main.mobile.doss.co.id/storage/uploads/2021/11/account-compressed.webp"
                  }
                  alt="no-account"
                />
              </div>

              <div className="mt-2 name-txt">
                {userInfo && userInfo.name
                  ? userInfo.name
                  : userInfo && userInfo.fullname
                  ? userInfo.fullname
                  : null}
              </div>

              <div className="mt-2 join-btn" onClick={!isJoined && handleJoin}>
                {isJoined ? "You Registered in Event" : "Join Event"}
              </div>
            </div>
          )}

          <div className="p-3 d-flex flex-column justify-content-center align-items-center">
            {/* <div
              className="sidebar-top-btn mb-3"
              style={{
                color: activeMenu === "tentang" && "#f27420",
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                goToTentang();
                handleCollapsed(true);
              }}
            >
              TENTANG IDCC
            </div> */}
            <div
              className="sidebar-top-btn mb-3"
              style={{ color: activeMenu === "lomba" && "#f27420" }}
              onClick={() => {
                goToLomba();
                handleCollapsed(true);
              }}
            >
              LOMBA IDCC 2021
            </div>
            {/* <div
              className="sidebar-top-btn mb-3"
              style={{ color: activeMenu === "hadiah" && "#f27420" }}
              onClick={() => {
                goToHadiah();
                handleCollapsed(true);
              }}
            >
              HADIAH
            </div>
            <div
              className="sidebar-top-btn mb-3"
              style={{ color: activeMenu === "acara" && "#f27420" }}
              onClick={() => {
                goToAcara();
                handleCollapsed(true);
              }}
            >
              ACARA
            </div> */}
          </div>
        </SidebarContent>

        <SidebarFooter>
          <div
            className="mt-1 d-flex flex-column flex-nowrap align-items-center justify-content-center"
            style={{ color: "white", whiteSpace: "nowrap" }}
          >
            <div>Indonesia Creative Competition </div>
            <div>2021 &copy;</div>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </>
  );
};

export default Sidebar;
