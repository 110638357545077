import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { MdClose } from "react-icons/md";
import OtpInput from "react-otp-input";

//Configs
import { API, setAuthToken } from "../configs/API";

const ModalConfirmOTP = ({
  show,
  handleClose,
  setIsLogin,
  setUserInfo,
  setModalSuccess,
  OTPType,
  setOTPType,
  openOTP,
  setMessage,
}) => {
  const [alertFailed, setAlertFailed] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [otp, setOtp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleChange = (value) => {
    setOtp(value);
  };

  const handleSubmitPhone = async (e) => {
    e.preventDefault();
    try {
      setAlertFailed(false);
      setAlertSuccess(false);
      setIsLoading(true);

      const phone = localStorage.getItem("phone");
      const token = localStorage.getItem("token");

      let res;
      let activatedAt;
      let email;

      if (token) {
        setAuthToken(token);
        const body = { otp: otp };
        res = await API.post("info/account/phone", body);
        activatedAt = res.data.data.activated_at;
        email = res.data.data.email;
      } else {
        const body = { phone: phone, otp: otp };
        res = await API.post("auth/login/otp", body);
        activatedAt = res.data.data.user.activated_at;
        email = res.data.data.user.email;
      }

      if (res.data.error >= 400) {
        setAlertFailed(true);
        setIsLoading(false);
      } else if (activatedAt === null) {
        localStorage.setItem("email", email);
        setIsLoading(false);
        handleClose();
        resetState();

        setMessage("Nomor Handphone Kamu Berhasil di Verifikasi");
        setModalSuccess(true);

        let timeout = setTimeout(() => {
          setModalSuccess(false);
          clearTimeout(timeout);
        }, 3500);

        setOTPType("Email");
        openOTP();
      } else {
        // const token = localStorage.getItem("token");
        setAuthToken(res.data.data.access_token.token);

        const res2 = await API.get("info/account?referral_code=GJIYNM0H");

        setAlertFailed(false);

        setIsLogin(true);
        setUserInfo(res2.data.data);

        resetState();

        setMessage("Kamu Berhasil Bergabung di Event IDCC 2021");
        setModalSuccess(true);

        let timeout = setTimeout(() => {
          setModalSuccess(false);
          window.location.href = `https://idcc.doss.co.id?code=${res2.data.data.code}`;
          clearTimeout(timeout);
        }, 3500);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSubmitOTP = async (e) => {
    e.preventDefault();
    try {
      setAlertFailed(false);
      setAlertSuccess(false);
      setIsLoading(true);
      const email = localStorage.getItem("email");

      const body = {
        email: email,
        otp: otp,
      };

      const res = await API.post("auth/activate", body);

      if (res.data.error == 422) {
        setAlertFailed(true);
        setIsLoading(false);
      } else {
        const token = localStorage.getItem("token");
        setAuthToken(token);

        const res2 = await API.get("info/account?referral_code=GJIYNM0H");

        setAlertFailed(false);

        setIsLogin(true);
        setUserInfo(res2.data.data);

        resetState();

        setIsLoading(false);

        setMessage("Kamu Berhasil Bergabung di Event IDCC 2021");
        setModalSuccess(true);

        let timeout = setTimeout(() => {
          setModalSuccess(false);
          window.location.href = `https://idcc.doss.co.id?code=${res2.data.data.code}`;
          clearTimeout(timeout);
        }, 3500);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const resendOTP = async () => {
    try {
      setIsLoading(true);
      if (OTPType === "Phone") {
        const phone = localStorage.getItem("phone");
        const res = await API.post("auth/login/otp/request", { phone: phone });
        if (res.data.error == 403) {
          alert(res.data.message);
        }
        if (res.data.error == 0) {
          setMinutes(1);

          setAlertSuccess(true);
        }
        setIsLoading(false);
      } else {
        const email = localStorage.getItem("email");
        const res = await API.post("auth/otp/email/resend", { email: email });
        if (res.data.error == 403) {
          alert(res.data.message);
        }
        if (res.data.error == 0) {
          setMinutes(1);

          setAlertSuccess(true);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setAlertFailed(false);
    setOtp(null);
    handleClose();
  };

  return (
    <Modal centered show={show} onHide={resetState} backdrop="static">
      <Modal.Body>
        <div className="d-flex justify-content-between mb-4">
          <div className="modal-header-txt">VERIFIKASI</div>
          <MdClose
            color="#FFF"
            size={24}
            className="cursor-pointer"
            onClick={resetState}
          />
        </div>

        <div className="flex-center">
          <Form
            onSubmit={() =>
              OTPType === "Phone" ? handleSubmitPhone() : handleSubmitOTP()
            }
          >
            <Form.Text className="mb-3 text-center" style={{ color: "#FFFF" }}>
              Masukkan Kode OTP dari{" "}
              {OTPType === "Phone" ? "Nomor HP" : "Email"} kamu{" "}
              {seconds > 0 && `(${seconds})`}
            </Form.Text>
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={<span></span>}
              containerStyle="otp-container"
              inputStyle="otp-input"
              focusStyle="otp-focus"
              isInputNum={true}
              placeholder=""
              className="otp mb-3"
            />

            <Form.Text
              className="mb-3 text-center resend-txt"
              style={{ color: "#FFFF" }}
              onClick={resendOTP}
            >
              {seconds === 0 && "Kirim Ulang Kode OTP"}
            </Form.Text>

            {alertFailed && (
              <Alert variant="danger" className="text-center">
                Kode OTP Salah
              </Alert>
            )}

            {alertSuccess && (
              <Alert variant="success" className="text-center">
                Kode OTP berhasil dikirim ke{" "}
                {OTPType === "Phone" ? "Nomor HP" : "Email"} kamu
              </Alert>
            )}

            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={otp == null || otp.length < 6}
              className="modal-otp-btn text-center"
              onClick={(e) =>
                OTPType === "Phone" ? handleSubmitPhone(e) : handleSubmitOTP(e)
              }
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Konfirmasi"
              )}
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalConfirmOTP;
