import { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  NavDropdown,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { MdClose } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import Cookies from "js-cookie";
import jsonData from "./json/downloadidcc2021.json";
import { TiGroup } from "react-icons/ti";
import { AiFillCamera } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { MdCloudDownload } from "react-icons/md";

import "./App.scss";

// Asset
// import IDCCWhite from "./assets/images/idcc-white-webp.webp";
// import LensaLogo from "./assets/images/lensa-logo-webp.webp";
// import YuasaLogo from "./assets/images/yuasa.svg";
// import BGMountain from "./assets/images/mount-bg-webp.webp";
// import DOSSLogo from "./assets/images/doss-logo-webp.webp";
// import GUMPLogo from "./assets/images/gump-logo-webp.webp";
// import CaffeineLogo from "./assets/images/caffeine-white-webp.webp";

// Component
import Sidebar from "./components/Sidebar";
import useWindowDimensions from "./helpers/useWindowDimensions";
import ModalLogin from "./components/ModalLogin";
import ModalRegister from "./components/ModalRegister";
import ModalConfirmOTP from "./components/ModalConfirmOTP";
import ModalSuccess from "./components/ModalSuccess";
import ModalConfirmJoin from "./components/ModalConfirmJoin";
import ModalZoomImage from "./components/ModalZoomImage";
import ModalZoomGallery from "./components/ModalZoomGallery";
import ModalPhoneInput from "./components/ModalPhoneInput";
import ModalForgotPassword from "./components/ModalForgotPassword";
import EditingGalleryTab from "./components/EditingGalleryTab";
import EditingRuleTab from "./components/EditingRuleTab";
import EditingPrize from "./components/EditingPrize";
import PhotoRuleTab from "./components/PhotoRuleTab";
import PhotoPrizeTab from "./components/PhotoPrizeTab";
import OtomotifRuleTab from "./components/OtomotifRuleTab";
import OtomotifPrizeTab from "./components/OtomotifPrizeTab";
import { API, APIIDCC, setAuthToken } from "./configs/API";

const imgUKM = [
  {
    name: "martografi",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-martografi-ukp.webp",
  },
  {
    name: "islam lamongan",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-ukm-fotografi-unisla.webp",
  },
  {
    name: "conventry",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-01.webp",
  },
  {
    name: "fusy",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-ukm-fotografi-usu.webp",
  },
  {
    name: "pintu",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-ukm-fotografi-usu.webp",
  },
  {
    name: "indocjus",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/whatsapp-image-2021-10-29-at-6-59-31-pm.webp",
  },
  {
    name: "clic",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-clic-transparan.webp",
  },
  {
    name: "unit fotografi",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-ufo-utama-kotak.webp",
  },
  {
    name: "ukafo",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-ukafo.webp",
  },
  {
    name: "tarumanagara",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-pft-untar.webp",
  },
  {
    name: "zoom",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-zoom.webp",
  },
  {
    name: "bingkai",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/bingkai-logo-1.webp",
  },
  {
    name: "kemenibud",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-sephia-kemenibud-ukwms.webp",
  },
  {
    name: "klifonara",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/klifonara1.webp",
  },
  {
    name: "koma",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-koma-putih.webp",
  },
  {
    name: "chiphoc",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-ciphoc.webp",
  },
  {
    name: "blitz",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-blitz-uin-raden-intan-lampung.webp",
  },
  {
    name: "foster",
    img: "https://main.mobile.doss.co.id/storage/uploads/2021/12/logo-foster-new.webp",
  },
];

function App() {
  const [scrollTop, setScrollTop] = useState(0);
  const [activeMenu, setActiveMenu] = useState("");
  const [modalRegister, setModalRegister] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalOTP, setModalOTP] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalZoom, setModalZoom] = useState(false);
  const [modalZoomGallery, setModalZoomGallery] = useState(false);
  const [modalForgot, setModalForgot] = useState(false);
  const [modalPhone, setModalPhone] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [isJoined, setIsJoined] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeContest, setActiveContest] = useState("EDITING COMPETITION");
  const [activeTab, setActiveTab] = useState(1);
  const [OTPType, setOTPType] = useState("");
  const [message, setMessage] = useState("");
  const [indexImg, setIndexImg] = useState(0);
  const { height, width } = useWindowDimensions();

  console.log("Update 13 Desember 2021");

  const { photos } = jsonData;

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      // console.log("current", currentPosition);
      if (currentPosition >= 9266) {
        return setActiveMenu("acara");
      } else if (currentPosition >= 5016) {
        return setActiveMenu("hadiah");
      } else if (currentPosition >= 656) {
        return setActiveMenu("lomba");
      } else if (currentPosition >= 756) {
        return setActiveMenu("tentang");
      } else {
        return setActiveMenu("");
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      });
    });
  }, []);

  const refAcara = useRef(null);
  const refHadiah = useRef(null);
  const refLomba = useRef(null);
  const refTentang = useRef(null);
  const refEditing = useRef(null);
  const refPhoto = useRef(null);
  const refGallery = useRef(null);
  const refSelectedContest = useRef(null);
  const refOurContest = useRef(null);
  const refEditingContest = useRef(null);
  const refPhotoContest = useRef(null);
  const refSpecialContest = useRef(null);
  const refDownloadRAW = useRef(null);

  const goToAcara = () =>
    refAcara.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const goToHadiah = () =>
    refHadiah.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToLomba = () =>
    refLomba.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToTentang = () =>
    refTentang.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToEditing = () =>
    refEditing.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToPhoto = () =>
    refPhoto.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToGallery = () =>
    refGallery.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToSelectedContest = () =>
    refSelectedContest.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToOurContest = () =>
    refOurContest.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToEditingContest = () =>
    refEditingContest.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToPhotoContest = () =>
    refPhotoContest.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToSpecialContest = () =>
    refSpecialContest.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const goToDownloadRAW = () =>
    refDownloadRAW.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const toLogin = () => {
    setModalRegister(false);
    setModalLogin(true);
  };

  const toRegister = () => {
    setModalLogin(false);
    setModalRegister(true);
  };

  const handleLogout = () => {
    setIsLogin(false);
    localStorage.removeItem("identifier");
    setUserInfo(null);
  };

  // const _csrfToken = "{{csrf_token()}}";
  const _csrfToken = Cookies.get("_csrfToken");
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");

  const checkCode = () => {
    if (null == code) {
      checkUser();
    } else {
      getIdentifier();
    }
  };

  // const getIdentifier = () => {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("POST", "/r/get-identifier");
  //   xhr.setRequestHeader("Content-type", "application/json");
  //   xhr.setRequestHeader("X-CSRF-TOKEN", _csrfToken);

  //   xhr.onreadystatechange = async function () {
  //     if (xhr.readyState == XMLHttpRequest.DONE) {
  //       const resp = await JSON.parse(xhr.responseText);
  //       if (resp.error == 401) {
  //         getPartnerDetail();
  //       }
  //       if (resp.error == 0 && typeof resp.data.customer.id != "undefined") {
  //         localStorage.setItem("identifier", resp.data.access_token);
  //         checkUser();
  //         window.location.href = "/";
  //       }
  //     }
  //   };

  //   xhr.send(
  //     JSON.stringify({
  //       code: code,
  //       _token: _csrfToken,
  //     })
  //   );

  //   console.log("getIdentifier");
  // };

  const getIdentifier = async () => {
    try {
      const body = JSON.stringify({
        code: code,
        _token: _csrfToken,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": _csrfToken,
        },
      };

      const res = await APIIDCC.post("get-identifier", body, config);

      if (res.data.error == 401) {
        getPartnerDetail();
      }

      if (
        res.data.error == 0 &&
        typeof res.data.data.customer.id != "undefined"
      ) {
        await localStorage.setItem("identifier", res.data.data.access_token);
        await join();
        window.open("https://idcc.doss.co.id/", "_self");
        // checkUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const join = () => {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("POST", "/r/join");
  //   xhr.setRequestHeader("Content-type", "application/json");
  //   xhr.setRequestHeader("X-CSRF-TOKEN", _csrfToken);
  //   xhr.setRequestHeader(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("identifier")
  //   );

  //   xhr.onreadystatechange = async function () {
  //     if (xhr.readyState == XMLHttpRequest.DONE) {
  //       const resp = await JSON.parse(xhr.responseText);
  //     }
  //   };

  //   xhr.send();

  //   console.log(
  //     "join with identifier localstorage",
  //     localStorage.getItem("identifier")
  //   );
  // };

  const join = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": _csrfToken,
          Authorization: `Bearer ${localStorage.getItem("identifier")}`,
        },
      };

      const res = await APIIDCC.post("join", null, config);

      checkUser();

      setLoading(false);

      setModalConfirm(false);

      setModalSuccess(true);

      let timeout = setTimeout(() => {
        setModalSuccess(false);
        clearTimeout(timeout);
      }, 3500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const getPartnerDetail = () => {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("GET", "/r/get-partner-detail");
  //   xhr.setRequestHeader("Content-type", "application/json");
  //   xhr.setRequestHeader("X-CSRF-TOKEN", _csrfToken);

  //   xhr.onreadystatechange = async function () {
  //     if (xhr.readyState == XMLHttpRequest.DONE) {
  //       const resp = await JSON.parse(xhr.responseText);
  //       if (resp.error == 401) {
  //         // getRedirectUrl()
  //       } else {
  //         setReferralCode(resp.referral_code);
  //       }
  //     }
  //   };

  //   xhr.send();

  //   console.log("getPartnerDetail");
  // };

  const getPartnerDetail = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": _csrfToken,
        },
      };

      const res = await APIIDCC.get("get-partner-detail", config);

      setReferralCode(res.data.data.referral_code);
    } catch (error) {
      console.log(error);
    }
  };

  // const checkUser = () => {
  //   let xhr = new XMLHttpRequest();
  //   xhr.open("GET", "/r/fetch-user", true);
  //   xhr.setRequestHeader("Accept", "application/json");
  //   xhr.setRequestHeader("Content-Type", "application/json");
  //   xhr.setRequestHeader(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("identifier")
  //   );

  //   xhr.onreadystatechange = async function () {
  //     if (xhr.readyState == XMLHttpRequest.DONE) {
  //       const resp = await JSON.parse(xhr.responseText);
  //       if (resp.error == 401) {
  //         getRedirectUrl();
  //       }
  //       if (resp.error == 0) {
  //         setIsLogin(true);
  //         setUserInfo(resp.data.customer);

  //         if (!resp.data.joined) {
  //           setIsJoined(false);
  //           var reg = document.getElementById("register");
  //           reg.style.display = "none";
  //           var btn = document.getElementById("join");
  //           btn.style.display = "block";
  //         }
  //       }
  //     }
  //   };

  //   xhr.send(null);

  //   console.log(
  //     "checkUser with identifier localstorage",
  //     localStorage.getItem("identifier")
  //   );
  // };

  const checkUser = async () => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("identifier")}`,
        },
      };
      const res = await APIIDCC.get("fetch-user", config);
      if (res.data.error == 401) {
        // getRedirectUrl();
      }

      if (res.data.error == 0) {
        setIsLogin(true);
        setUserInfo(res.data.data.customer);

        if (!res.data.data.joined) {
          setIsJoined(false);
          var reg = document.getElementById("register");
          reg.style.display = "none";
          var btn = document.getElementById("join");
          btn.style.display = "block";
        } else {
          setIsJoined(true);
          var reg = document.getElementById("register");
          reg.style.display = "none";
          var btn = document.getElementById("join");
          btn.style.display = "none";
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getRedirectUrl = () => {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("GET", "/r/get-redirect-url", true);
  //   xhr.onreadystatechange = async function () {
  //     if (xhr.readyState == XMLHttpRequest.DONE) {
  //       const resp = await JSON.parse(xhr.responseText);
  //       window.location.href = resp.data.redirect;
  //       // window.open(resp.data.redirect)
  //     }
  //   };
  //   xhr.send(null);

  //   console.log("getRedirectUrl");
  // };

  const getRedirectUrl = async () => {
    try {
      const res = await APIIDCC.get("get-redirect-url");
      window.location.href = res.data.data.redirect_alt;
      // window.open(res.data.data.redirect_alt);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDaftar = () => {
    window.open(
      "https://doss.co.id/partner-auth?redirect=idcc.doss.co.id&referral_code=GJIYNM0H&register=true",
      "_self"
    );
  };

  const handleMasuk = () => {
    window.open(
      "https://doss.co.id/partner-auth?redirect=idcc.doss.co.id&referral_code=GJIYNM0H&login=true",
      "_self"
    );
  };

  useEffect(() => {
    checkCode();
  }, []);

  return (
    <>
      <ModalLogin
        show={modalLogin}
        handleClose={() => setModalLogin(false)}
        referralCode={referralCode}
        setIsLogin={(e) => setIsLogin(e)}
        setUserInfo={(e) => setUserInfo(e)}
        toRegister={toRegister}
        openOTP={() => setModalOTP(true)}
        openForgot={() => setModalForgot(true)}
        OTPType={OTPType}
        setOTPType={(e) => setOTPType(e)}
        openPhone={() => setModalPhone(true)}
      />

      <ModalRegister
        show={modalRegister}
        handleClose={() => setModalRegister(false)}
        referralCode={referralCode}
        toLogin={toLogin}
        openOTP={() => setModalOTP(true)}
        setIsLogin={(e) => setIsLogin(e)}
        setUserInfo={(e) => setUserInfo(e)}
        OTPType={OTPType}
        setOTPType={(e) => setOTPType(e)}
      />

      <ModalConfirmOTP
        show={modalOTP}
        handleClose={() => setModalOTP(false)}
        setIsLogin={(e) => setIsLogin(e)}
        setUserInfo={(e) => setUserInfo(e)}
        setModalSuccess={(e) => setModalSuccess(e)}
        setMessage={(e) => setMessage(e)}
        openOTP={() => setModalOTP(true)}
        OTPType={OTPType}
        setOTPType={(e) => setOTPType(e)}
      />

      <ModalSuccess
        show={modalSuccess}
        handleClose={() => setModalSuccess(false)}
        message={message}
      />

      <ModalConfirmJoin
        show={modalConfirm}
        handleClose={() => setModalConfirm(false)}
        join={join}
        loading={loading}
      />

      <ModalZoomImage
        show={modalZoom}
        handleClose={() => setModalZoom(false)}
        image="https://main.mobile.doss.co.id/storage/uploads/2021/12/60-komunitas.webp"
      />

      <ModalZoomGallery
        show={modalZoomGallery}
        handleClose={() => setModalZoomGallery(false)}
        images={photos}
        indexImg={indexImg}
      />

      <ModalForgotPassword
        show={modalForgot}
        handleClose={() => setModalForgot(false)}
      />

      <ModalPhoneInput
        show={modalPhone}
        handleClose={() => setModalPhone(false)}
        openOTP={() => setModalOTP(true)}
        setOTPType={(e) => setOTPType(e)}
      />

      <div
        className={collapsed ? "" : "dim"}
        onClick={() => setCollapsed(true)}
      />

      <Sidebar
        collapsed={collapsed}
        handleCollapsed={(e) => setCollapsed(e)}
        activeMenu={activeMenu}
        onPressRegister={() => setModalRegister(true)}
        onPressLogin={() => setModalLogin(true)}
        goToTentang={goToTentang}
        goToLomba={goToOurContest}
        goToHadiah={goToHadiah}
        goToAcara={goToAcara}
        isLogin={isLogin}
        logout={handleLogout}
        userInfo={userInfo}
        isJoined={isJoined}
        handleJoin={() => setModalConfirm(true)}
      />

      <Container
        fluid
        className={activeMenu === "" ? "fixed-navbar-first" : "fixed-navbar"}
      >
        <Container>
          <div className="first-top-navbar-mobile py-3">
            <div
              md={12}
              className="d-flex align-items-center justify-content-between"
            >
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/idcc-white.webp"
                alt="logo-idcc"
                className="logo-idcc"
              />
              <GiHamburgerMenu
                color="#FFFF"
                size={35}
                onClick={() => setCollapsed(false)}
              />
            </div>
          </div>

          <Row className="first-top-navbar py-3">
            <Col md={3}>
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/idcc-white.webp"
                alt="logo-idcc"
                className="logo-idcc"
              />
            </Col>

            <Col
              md={9}
              className="d-flex w-100 justify-content-center align-items-center top-main-menu-container"
            >
              <div
                className={`px-4 py-2 ${
                  activeMenu === ""
                    ? "top-main-menu-first-inactive"
                    : "top-main-menu-inactive"
                }`}
                // onClick={goToHadiah}
              >
                ______
              </div>
              <div
                className={`px-4 py-2 ${
                  activeMenu === ""
                    ? "top-main-menu-first-inactive"
                    : "top-main-menu-inactive"
                }`}
                // onClick={goToAcara}
              >
                _________
              </div>

              <div
                className={`px-4 py-2 ${
                  activeMenu === ""
                    ? "top-main-menu-first-inactive"
                    : "top-main-menu-inactive"
                }`}
                // onClick={goToTentang}
              >
                _______
              </div>

              <div
                className={`px-4 py-2 ${
                  activeMenu === ""
                    ? "top-main-menu-first"
                    : `${
                        activeMenu === "lomba"
                          ? "top-main-menu-active"
                          : "top-main-menu"
                      }`
                }`}
                onClick={goToOurContest}
              >
                LOMBA IDCC 2021
              </div>

              {!isLogin && (
                <div
                  className={`px-4 py-2 ${
                    activeMenu === ""
                      ? "top-main-menu-first-active"
                      : "top-main-menu-active"
                  }`}
                  onClick={() => setModalRegister(true)}
                >
                  DAFTAR
                </div>
              )}

              {isLogin && isJoined == false && (
                <div
                  className={`px-4 py-2 ${
                    activeMenu === ""
                      ? "top-main-menu-first-active"
                      : "top-main-menu-active"
                  }`}
                  onClick={() => setModalConfirm(true)}
                >
                  JOIN EVENT
                </div>
              )}

              {!isLogin && (
                <div
                  className={`px-4 py-2 ${
                    activeMenu === ""
                      ? "top-main-menu-first-active"
                      : "top-main-menu-active"
                  }`}
                  onClick={() => setModalLogin(true)}
                >
                  MASUK
                </div>
              )}

              {isLogin && (
                <NavDropdown
                  className={` ${
                    activeMenu === ""
                      ? "top-main-menu-first-active-2"
                      : "top-main-menu-active-2"
                  }`}
                  title="ACCOUNT"
                  show={showDropdown}
                  onMouseEnter={() => setShowDropdown(true)}
                  onMouseLeave={() => setShowDropdown(false)}
                >
                  <NavDropdown.Item>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="account-img-container">
                        <img
                          src={
                            userInfo
                              ? userInfo.avatar
                              : "https://main.mobile.doss.co.id/storage/uploads/2021/11/account-compressed.webp"
                          }
                          alt="no-account"
                        />
                      </div>
                      <div className="mt-2 account-name-txt">
                        {userInfo && userInfo.name
                          ? userInfo.name
                          : userInfo && userInfo.fullname
                          ? userInfo.fullname
                          : null}
                      </div>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item onClick={handleLogout}>
                    <div className="item-container-logout py-2">
                      <span className="color-grey ml-1">Logout</span>
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        fluid
        className={
          activeMenu === ""
            ? "top-main-nav main-container-first"
            : "top-main-nav main-container"
        }
      >
        <Container className="position-relative">
          {/* LANDING PAGE */}
          <Row className="mt-5">
            <Col md={3}></Col>
            <Col md={9}>
              <div className="mt-5 px-45 first-main-content-top">
                THE LARGEST ONLINE CREATIVE COMPETITION
              </div>

              <div className="px-45 d-flex justify-content-center mt-4 mb-3">
                <img
                  src="https://main.mobile.doss.co.id/storage/uploads/2021/12/yuasa-logo.webp"
                  alt="logo-yuasa"
                  className="main-spons-img"
                />
              </div>

              <div className="px-45 d-flex justify-content-center">
                <img
                  src="https://main.mobile.doss.co.id/storage/uploads/2021/11/doss-logo-webp.webp"
                  alt="doss-logo"
                  className="second-spons-img mr-4"
                />
                <img
                  src="https://main.mobile.doss.co.id/storage/uploads/2021/12/lensa-white.webp"
                  alt="lensa-logo"
                  className="second-spons-img"
                />
              </div>

              <div className="reserved-sponsor" />

              <div className="px-45 d-flex justify-content-center mt-5">
                {/* {userInfo && userInfo.joined == false && (
                  <div
                    id="join"
                    className="button-red mr-2"
                    style={{ width: "200px", display: "none" }}
                    onClick={() => setModalConfirm(true)}
                  >
                    JOIN EVENT
                  </div>
                )} */}

                <div
                  id="join"
                  className="button-red mr-2"
                  style={{ width: "200px", display: "none" }}
                  onClick={() => setModalConfirm(true)}
                >
                  JOIN EVENT
                </div>

                <div
                  id="register"
                  className="button-red mr-2"
                  style={{ width: "200px", display: "block" }}
                  onClick={() => setModalRegister(true)}
                >
                  REGISTER
                </div>
                <div
                  className="button-yellow"
                  style={{ width: "200px" }}
                  onClick={() => {
                    if (isLogin) {
                      setActiveTab(3);
                      goToDownloadRAW();
                    } else {
                      alert(
                        "Mohon Register/Login dahulu untuk menggunakan fitur ini"
                      );
                    }
                  }}
                >
                  DOWNLOAD
                </div>
              </div>

              <div className="px-45 d-flex justify-content-center mt-3">
                <div className="mr-4 d-flex flex-column align-items-center">
                  <div className="first-main-content-p mb-2">ORGANIZED BY</div>
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/11/caffeine-white-webp.webp"
                    alt="caffeine-logo"
                    className="second-spons-img"
                  />
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="first-main-content-p mb-3">SUPPORTED BY</div>
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/11/gump-logo-webp.webp"
                    alt="gump-logo"
                    className="gump-logo"
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="section-separator" />
          <div className="section-separator" ref={refOurContest} />

          {/* OUR CONTEST */}
          <Row id="contest">
            <Col md={3}></Col>
            <Col md={9}>
              <h1
                className="mt-4 px-45 first-main-content"
                style={{ textAlign: "center" }}
              >
                OUR CONTESTS!
              </h1>
              <div className="mt-2 px-45 d-flex align-items-center justify-content-around flex-wrap">
                <div className="d-flex mt-4 mx-2 flex-column align-items-center justify-content-center contest-content">
                  <div className="contest-img-container">
                    <img
                      src="https://main.mobile.doss.co.id/storage/uploads/2021/12/idcc-photo-editing.webp"
                      alt="photo-editing"
                      className="contest-img"
                      onClick={() => {
                        goToEditingContest();
                      }}
                    />
                  </div>

                  <div className="contest-txt mt-3">EDITING COMPETITION</div>
                </div>
                <div className="d-flex mt-4 mx-2 flex-column align-items-center justify-content-center contest-content">
                  <div className="contest-img-container">
                    <img
                      src="https://main.mobile.doss.co.id/storage/uploads/2021/12/idcc-photo-competition.webp"
                      alt="photo-editing"
                      className="contest-img"
                      onClick={() => {
                        goToPhotoContest();
                      }}
                    />
                  </div>

                  <div className="contest-txt mt-3">PHOTO COMPETITION</div>
                </div>
                <div className="d-flex mt-4 mx-2 flex-column align-items-center justify-content-center contest-content">
                  <div className="contest-img-container">
                    <img
                      src="https://main.mobile.doss.co.id/storage/uploads/2021/12/idcc-special-competition.webp"
                      alt="photo-editing"
                      className="contest-img"
                      onClick={() => {
                        goToSpecialContest();
                      }}
                    />
                  </div>

                  <div className="contest-txt mt-3">SPECIAL COMPETITION</div>
                </div>
              </div>
              <div className="px-45 mt-5 contest-desc-txt">
                Peserta dapat mengikuti seluruh kompetisi tanpa ada batasan,
                tetapi harus mengikuti peraturan dan tata cara masing-masing
                lomba.
              </div>
            </Col>
          </Row>

          <div className="section-separator" />
          <div className="section-separator" ref={refEditingContest} />

          {/* EDITING COMPETITION */}
          <Row id="editing-contest">
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">
                EDITING COMPETITION
              </h1>

              <p className="mt-4 px-45 first-main-content-p">
                IDCC Editing Competition merupakan kontes editing dengan file
                yang telah disediakan oleh panitia. Peserta diharuskan mendaftar
                dan kemudian dapat mendownload file RAW maupun JPG yang telah
                disediakan, lalu upload hasil editing ke social media sesuai
                dengan peraturan yang telah ditentukan.
                <br />
              </p>

              <div className="px-45">
                <div
                  id="register"
                  className="button-red mr-2 mt-2 mb-4"
                  style={{ width: "250px", display: "block" }}
                  onClick={() => {
                    setActiveTab(3);
                    goToDownloadRAW();
                  }}
                >
                  DOWNLOAD RAW FILE
                </div>
              </div>

              <div className="px-45 contest-container" ref={refDownloadRAW}>
                <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e)}>
                  <Tab eventKey="1" title="HADIAH">
                    <EditingPrize />
                  </Tab>

                  <Tab eventKey="2" title="PERATURAN">
                    <EditingRuleTab />
                  </Tab>

                  <Tab eventKey="3" title="DOWNLOAD">
                    <EditingGalleryTab
                      photos={photos}
                      isLogin={isLogin}
                      openLogin={() => setModalLogin(true)}
                      openZoom={() => setModalZoomGallery(true)}
                      setIndexImg={(e) => setIndexImg(e)}
                    />
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>

          <div className="section-separator" />
          <div className="section-separator" ref={refPhotoContest} />

          {/* PHOTO COMPETITION */}
          <Row id="photo-contest">
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">
                PHOTO COMPETITION
              </h1>

              <p className="mt-4 px-45 first-main-content-p">
                Peserta dapat mengikuti kompetisi dengan tema lomba adalah UNITY
                atau SURVIVAL
              </p>

              <p className="px-45 first-main-content-p">
                Terbuka untuk <strong>semua</strong> genre fotografi dan
                tersedia juga genre khusus yaitu <strong>OTOMOTIF</strong>.
              </p>

              <p className="px-45 first-main-content-p">
                Peserta dapat menggunakan segala jenis kamera untuk mengikuti
                photo contest IDCC 2021 baik amatir maupun profesional.
              </p>

              <div className="px-45 contest-container">
                <Tabs defaultActiveKey="1">
                  <Tab eventKey="1" title="HADIAH">
                    <PhotoPrizeTab />
                  </Tab>

                  <Tab eventKey="2" title="PERATURAN">
                    <PhotoRuleTab />
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>

          <div className="section-separator" />
          <div className="section-separator" ref={refSpecialContest} />

          {/* SPECIAL COMPETITION */}
          <Row id="special-contest">
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">
                SPECIAL COMPETITION
              </h1>

              <p className="mt-4 px-45 first-main-content-p">
                Peserta dapat mengikuti kompetisi dengan tema lomba adalah UNITY
                atau SURVIVAL
              </p>

              <p className="px-45 first-main-content-p">
                Terbuka untuk <strong>semua</strong> genre fotografi dan
                tersedia juga genre khusus yaitu <strong>OTOMOTIF</strong>.
              </p>

              <p className="px-45 first-main-content-p">
                Peserta dapat menggunakan segala jenis kamera untuk mengikuti
                photo contest IDCC 2021 baik amatir maupun profesional.
              </p>

              <div className="px-45 contest-container">
                <Tabs defaultActiveKey="1">
                  <Tab eventKey="1" title="HADIAH">
                    <OtomotifPrizeTab />
                  </Tab>

                  <Tab eventKey="2" title="PERATURAN">
                    <OtomotifRuleTab />
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>

          {/* <div className="section-separator" ref={refTentang} /> */}

          {/* TENTANG IDCC */}
          {/* <Row id="tentang">
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">TENTANG IDCC</h1>
              <p className="mt-4 px-45 first-main-content-p ">
                Merupakan sebuah kegiatan kolaborasi untuk meningkatkan
                aktivitas di dunia kreatif yang sempat terpuruk sejak era
                pandemi.
                <br />
                Diprakarsai oleh komunitas Caffeine.id yang didukung oleh banyak
                pihak. IDCC diharapkan akan menjadi sebuah ajang tahunan yang
                terus berkembang.
              </p>

              <p className="px-45 first-main-content-p ">
                Kami bersama puluhan komunitas fotografi di seluruh Indonesia,
                mengajak para penggiat fotografi untuk dapat bersatu mengangkat
                kembali semangat dunia kreatif terutama di bidang fotografi.
              </p>

              <div className="px-45 d-flex mt-4 mb-5">
                <div className="button-red mr-2" onClick={goToEditing}>
                  EDITING CONTEST
                </div>
                <div className="button-yellow" onClick={goToPhoto}>
                  PHOTO CONTEST
                </div>
              </div>
            </Col>
          </Row> */}

          {/* <div className="section-separator" ref={refEditing} /> */}

          {/* EDITING CONTEST */}
          {/* <Row id="editing-contest">
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">EDITING CONTEST</h1>
              <p className="mt-4 px-45 first-main-content-p">
                Panitia akan menyediakan beragam foto RAW unedited yang dapat di
                download peserta
                <br />
              </p>

              <p className="px-45 first-main-content-p">
                Foto dapat diedit bebas dan sekreatif mungkin dengan TANPA
                ADANYA BATASAN.
              </p>

              <p className="px-45 first-main-content-p mt-4">
                <div
                  className="button-red"
                  style={{ width: "250px" }}
                  onClick={() => {
                    if (isLogin) {
                      goToGallery();
                    } else {
                      alert(
                        "Mohon Register/Login dahulu untuk menggunakan fitur ini"
                      );
                    }
                  }}
                >
                  DOWNLOAD RAW FILE
                </div>
              </p>
            </Col>
          </Row> */}

          {/* <div className="section-separator" ref={refPhoto} /> */}

          {/* PHOTO CONTEST */}
          {/* <Row id="photo-contest">
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">PHOTO CONTEST</h1>
              <p className="mt-4 px-45 first-main-content-p">
                Peserta dapat mengikuti kompetisi dengan tema lomba adalah UNITY
                atau SURVIVAL
              </p>

              <p className="px-45 first-main-content-p">
                Terbuka untuk <strong>semua</strong> genre fotografi dan
                tersedia juga genre khusus yaitu
                <strong>OTOMOTIF</strong>.
              </p>

              <p className="px-45 first-main-content-p">
                Peserta dapat menggunakan segala jenis kamera untuk mengikuti
                photo contest IDCC 2021 baik amatir maupun profesional.
              </p>
            </Col>
          </Row> */}

          {/* <div className="section-separator" ref={refLomba} /> */}

          {/* <div className="section-separator" /> */}

          {/* CARA PENDAFTARAN */}
          {/* <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">
                CARA PENDAFTARAN
              </h1>
              <p className="mt-4 px-45 first-main-content-p">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </Col>
          </Row> */}

          <div className="section-separator" ref={refHadiah} />

          {/* VALUE APPRECIATION UKM */}
          {/* <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">
                VALUE APPRECIATION
              </h1>
              <h3 className="px-45 first-main-content">
                FOR COMMUNITY & CAMPUS
              </h3>
              <div className="mt-2 px-45 first-main-content-p">
                SUPPORTING CAMPUS (UNIT KEGIATAN MAHASISWA)
              </div>
              <div className="mt-2 px-45 first-main-content-big-text">
                5.000.000
              </div>

              <div className="mt-2 px-45 first-main-content-p">
                <strong>Kategori penilaian utama:</strong>
                <br />
                Video Reels <strong>TERBAIK</strong> berdurasi maksimal 59 detik
                berisi konten ajakan/berhubungan dengan dengan IDCC.
              </div>

              <div className="mt-4 px-45 first-main-content-p">
                <strong>Peraturan Video Reels:</strong>
                <br />
                1. Komunitas membuat video Reels berdurasi max 59 detik di
                Instagram <br />
                2. Video Reels berisikan konten bebas yang bersifat ajakan atau
                promosi agar lebih banyak member komunitas mengikuti rangkaian
                acara IDCC <br />
                3. Video Reels di upload Instagram komunitas masing - masing dan
                mention serta menggunakan hashtag yang sudah ditentukan di lomba
                foto <br />
                4. Video paling menarik dan unik akan dipilih oleh dewan juri
                sebagai <strong>BEST SUPPORTING CAMPUS/UKM</strong> Winner
              </div>

              <div className="mt-4 px-45 first-main-content-p">
                <strong>Kategori penilaian pendukung:</strong>
                <br />
                - Banyaknya anggota yang melakukan posting <br />
                - Banyaknya anggota yang terlibat di acara IDCC (lomba,
                workshop, dll) <br />
                - Banyaknya video ajakan IDCC <br />
                - Banyaknya post mengenai IDCC <br />- Post paling kreatif dalam
                membuat konten
              </div>
            </Col>
          </Row> */}

          {/* <div className="section-separator" /> */}

          {/* VALUE APPRECIATION PHOTOGRAPHY */}
          {/* <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">
                VALUE APPRECIATION
              </h1>
              <h3 className="px-45 first-main-content">
                FOR COMMUNITY & CAMPUS
              </h3>
              <div className="mt-2 px-45 first-main-content-p">
                SUPPORTING PHOTOGRAPHY COMMUNITY
              </div>
              <div className="mt-2 px-45 first-main-content-big-text">
                5.000.000
              </div>

              <div className="mt-2 px-45 first-main-content-p">
                <strong>Kategori penilaian utama:</strong>
                <br />
                Video Reels <strong>TERBAIK</strong> berdurasi maksimal 59 detik
                berisi konten ajakan/berhubungan dengan dengan IDCC.
              </div>

              <div className="mt-4 px-45 first-main-content-p">
                <strong>Peraturan Video Reels:</strong>
                <br />
                1. Komunitas membuat video Reels berdurasi max 59 detik di
                Instagram <br />
                2. Video Reels berisikan konten bebas yang bersifat ajakan atau
                promosi agar lebih banyak member komunitas mengikuti rangkaian
                acara IDCC <br />
                3. Video Reels di upload Instagram komunitas masing - masing dan
                mention serta menggunakan hashtag yang sudah ditentukan di lomba
                foto <br />
                4. Video paling menarik dan unik akan dipilih oleh dewan juri
                sebagai <strong>BEST SUPPORTING COMMUNITY</strong> Winner
              </div>

              <div className="mt-4 px-45 first-main-content-p">
                <strong>Kategori penilaian pendukung:</strong>
                <br />
                - Banyaknya anggota yang melakukan posting <br />
                - Banyaknya anggota yang terlibat di acara IDCC (lomba,
                workshop, dll) <br />
                - Banyaknya video ajakan IDCC <br />
                - Banyaknya post mengenai IDCC <br />- Post paling kreatif dalam
                membuat konten
              </div>
            </Col>
          </Row> */}

          {/* <div className="section-separator" />
          <div className="section-separator" />
          <div className="section-separator" ref={refAcara} /> */}

          {/* WORKSHOP */}
          {/* <Row id="acara">
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">WORKSHOP</h1>
              <div
                className="px-45 first-main-content-p"
                style={{ textAlign: "left" }}
              >
                SUPPORTING PHOTOGRAPHY COMMUNITY
              </div>

              <Row className="px-45">
                <Col md={4} className="d-flex flex-column mt-4">
                  <div style={{ color: "#FFFF", fontSize: "120%" }}>TBA</div>
                  <img
                    className="image-card"
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/11/account.webp"
                    alt="hengky"
                    style={{ backgroundColor: "#FFFF" }}
                  />
                  <div className="card-text mt-2">TBA</div>
                  <div className="card-text card-italic">TBA</div>
                  <div className="card-text">Desember 2021</div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-center mt-4"
                >
                  <img
                    className="image-card"
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/fajar.webp"
                    alt="fajar-kristiono"
                  />
                  <div className="card-text mt-2">Fajar Kristiono</div>
                  <div className="card-text card-italic">TBA</div>
                  <div className="card-text">January 2022</div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-center mt-4"
                >
                  <img
                    className="image-card"
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/dheny-patungka.webp"
                    alt="dhenny-patungka"
                  />
                  <div className="card-text mt-2">Dhenny Patungka</div>
                  <div className="card-text card-italic">TBA</div>
                  <div className="card-text">February 2022</div>
                </Col>
              </Row>
            </Col>
          </Row> */}

          {/* <div className="section-separator" /> */}

          {/* IDCC JUDGES */}
          {/* <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">IDCC JUDGES</h1>

              <Row className="px-45">
                <Col
                  md={3}
                  className="d-flex flex-column align-items-center mt-4"
                >
                  <img
                    className="image-card-2"
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/hengki.webp"
                    alt="hengky"
                  />
                  <div className="card-text mt-2">Hengky Irawan</div>
                  <div className="card-text-2 card-italic">SONY ALPHA GURU</div>
                </Col>
                <Col
                  md={3}
                  className="d-flex flex-column align-items-center mt-4"
                >
                  <img
                    className="image-card-2"
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/fajar.webp"
                    alt="fajar"
                  />
                  <div className="card-text mt-2">Fajar Kristiono</div>
                  <div className="card-text-2 card-italic">
                    SONY ALPHA PROFESSIONAL
                  </div>
                </Col>
                <Col
                  md={3}
                  className="d-flex flex-column align-items-center mt-4"
                >
                  <img
                    className="image-card-2"
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/dheny-patungka.webp"
                    alt="dhenny"
                  />
                  <div className="card-text mt-2">Dhenny Patungka</div>
                  <div className="card-text-2 card-italic">
                    International Digital Artist
                  </div>
                </Col>
                <Col
                  md={3}
                  className="d-flex flex-column align-items-center mt-4"
                >
                  <img
                    className="image-card-2"
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/hardi-budi.webp"
                    alt="hardi"
                  />
                  <div className="card-text mt-2">Hardi Budi</div>
                  <div className="card-text-2 card-italic">
                    Nikon Official Photographer
                  </div>
                </Col>
              </Row>
            </Col>
          </Row> */}

          <div className="section-separator" />

          {/* IDCC SPONSOR */}
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">IDCC SPONSOR</h1>

              <div className="mt-5 px-45">
                <img
                  src="https://main.mobile.doss.co.id/storage/uploads/2021/12/yuasa-logo.webp"
                  alt="logo-yuasa"
                  className="yuasa-logo"
                />

                <div className="mt-3 d-flex">
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/11/doss-logo-webp.webp"
                    alt="logo-doss"
                    className="doss-logo"
                  />
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/lensa-white.webp"
                    alt="logo-lensa"
                    className="lensa-logo"
                  />
                </div>

                <div className="d-flex mt-4">
                  <div className="d-flex flex-column align-items-center mr-2">
                    <div className="org-txt">ORGANIZED BY</div>
                    <img
                      src="https://main.mobile.doss.co.id/storage/uploads/2021/11/caffeine-white-webp.webp"
                      alt="caffeine"
                      className="caffeine-logo mt-2"
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="org-txt">SUPPORTED BY</div>
                    <img
                      src="https://main.mobile.doss.co.id/storage/uploads/2021/11/gump-logo-webp.webp"
                      alt="gump"
                      className="gump-logo mt-2"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* <div className="section-separator" /> */}

          {/* IDCC LIVE SESSION */}
          {/* <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">
                IDCC LIVE SESSION
              </h1>
              <p className="mt-4 px-45 first-main-content-p ">
                Merupakan sesi tanya jawab melalui platform INSTAGRAM, antara
                panitia IDCC, juri dan pihak terkait dengan para peserta IDCC
                2021.
              </p>
              <p className="px-45 first-main-content-p ">
                Para peserta ataupun calon peserta berkesempatan untuk
                berkomunikasi langsung dengan panitia penyelenggara dan dapat
                menanyakan secara lebih detail mengenai tata cara lomba, maupun
                hal hal lain yang berkaitan dengan IDCC 2021.
              </p>

              <p className="px-45 first-main-content-p ">
                Live Session 1: December 2021
                <br />
                Live Session 2: January 2022
              </p>
            </Col>
          </Row> */}

          <div className="section-separator" />

          {/* CONTACT US */}
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">CONTACT US</h1>
              <p className="mt-4 px-45 first-main-content-p ">
                Untuk pertanyaan lebih lanjut dapat menghubungi :
              </p>
              <p className="px-45 first-main-content-p ">
                General question about IDCC
              </p>

              <p className="px-45 first-main-content-p ">
                <strong>Leontinus Jesse Putra</strong> <br />
                0878-2282-890
              </p>

              <p className="px-45 first-main-content-p ">
                Question about IDCC Community
              </p>

              <p className="px-45 first-main-content-p ">
                <strong>Johan Djayanto</strong> <br />
                0812-9166-6601
              </p>
            </Col>
          </Row>

          <div className="section-separator" />

          {/* IDCC SUPPORTING CAMPUS */}
          {/* <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">IDCC</h1>
              <h3 className="px-45 first-main-content">SUPPORTING CAMPUS</h3>
              <div className="mt-4 px-45 first-main-content-p grid-container">
                {imgUKM.map((img) => (
                  <img src={img.img} alt={img.name} className="img-ukm" />
                ))}
              </div>
            </Col>
          </Row> */}

          {/* <div className="section-separator" /> */}

          {/* IDCC SUPPORTING COMMUNITY */}
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="mt-4 px-45 first-main-content">IDCC</h1>
              <h3 className="px-45 first-main-content">
                SUPPORTING COMMUNITY & UKM
              </h3>
              <div className="mt-4 px-45 first-main-content-p">
                <img
                  src="https://main.mobile.doss.co.id/storage/uploads/2021/12/60-komunitas.webp"
                  alt="sponsor-dan-ukm"
                  style={{ width: "100%" }}
                  onClick={() => setModalZoom(true)}
                />
              </div>
            </Col>
          </Row>

          <div className="section-separator" />
          {/* <div className="section-separator" ref={refGallery} /> */}
        </Container>
      </Container>
    </>
  );
}

export default App;
