import React, { useState } from "react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { MdClose } from "react-icons/md";

//Configs
import { API, setAuthToken } from "../configs/API";

const ModalConfirmJoin = ({ show, handleClose, join, loading }) => {
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="d-flex justify-content-between mb-4">
          <div className="modal-header-txt">KONFIRMASI</div>
          <MdClose
            color="#FFF"
            size={24}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <div className="modal-confirm-txt">
          Apa kamu yakin ingin mendaftar untuk event IDCC 2021 ?
        </div>

        <div className="mt-4 d-flex justify-content-center align-items-center">
          <Button
            className="button-red mr-2"
            style={{ width: "100%" }}
            onClick={handleClose}
          >
            Batalkan
          </Button>
          <Button
            className="button-yellow ml-2"
            style={{ width: "100%" }}
            onClick={join}
          >
            {loading ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="mr-2"
              />
            ) : (
              "Ya, Lanjutkan"
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalConfirmJoin;
