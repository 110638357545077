import React from "react";
import { Row, Col } from "react-bootstrap";

const PhotoRuleTab = () => {
  return (
    <div className="tab-contest px-4 pb-4">
      <Row>
        <Col md={12}>
          <h1 className="mt-4 first-main-content">TATA TERTIB LOMBA FOTO</h1>
          <div className="mt-4 first-main-content-p">
            1. Lomba fotografi dengan tema UNITY dan SURVIVAL. <br />
            2. Registrasi keikutsertaan kamu ke idcc.doss.co.id lalu ikuti
            petunjuk untuk proses upload foto yang ingin kamu lombakan ke
            Instagram masing - masing.
            <br />
            3. Unggah foto yang kamu upload ke sosial media Instagram
            masing-masing dengan format caption: <br />
            <blockquote className="quote-rules">
              Kontes foto dan kontes editing terbesar di Indonesia dengan total
              hadiah 100 Juta Rupiah. <br />
              IDCC 2021 berkolaborasi dengan : <br />
              -------------------------------- <br />
              @idcc_id @caffeine.id @loveyuasa @doss.id @lensacommunity @gump.gg{" "}
              <br />
              - <br />
              - <br />
              Fotografer : (tag) <br />
              Komunitas : (tag) <br />
              Model : (tag) <br />
              Editor : (tag) <br />
              - <br />
              - <br />
              Yuk jangan sampai ketinggalan (tag 3 temanmu) <br />
              #IDCC2021 #IDCC2021photocompetition <br />
              #IndonesiaCreativeCompetition <br />
              #caffeineid <br />
              #LoveYuasa #LetsRockYourBattery #dossid <br />
              #dosscommunity #LensaCommunity <br />
              #gumpgg <br />
            </blockquote>
            4. Follow dan tag @idcc.id @cafeine.id @loveyuasa @doss.id
            @lensacommunity @gump.gg
            <br />
            5. Share postingan kalian dan ajak teman-teman kalian
            sebanyak-banyaknya untuk ikut event ini. <br />
            6. Akun Instagram tidak boleh di private (harus public). <br />
            7. Foto adalah hasil karya sendiri. <br />
            8. Foto yang diikutsertakan belum pernah memenangkan lomba
            sebelumnya. <br />
            9. Submit foto sebanyak yang kamu suka. <br />
            10. Periode lomba 3 Desember 2021 - 31 Maret 2022. <br />
            11. Bebas menggunakan kamera jenis apapun (DSLR, Mirrorless, Pocket
            Cam, Analog Cam, Smartphone, dll). <br />
            12. Konten foto tidak mengandung SARA. <br />
            13. Proses editing bebas, dan tidak ada batasan. <br />
            14. Pemenang lomba foto ditentukan oleh foto dan caption terbaik
            yang disubmit ke Instagram dan sesuai dengan tema yang ditentukan
            oleh panitia. <br />
            15. Pemenang diputuskan oleh dewan juri dan keputusan juri adalah
            mutlak. <br />
            16. Foto pemenang akan dipamerkan di website dan landing page resmi
            IDCC 2021 dan gump.gg. <br />
            17. Hasil karya foto peserta menjadi hak milik penyelenggara.
            <br />
            18. Untuk peserta yang berminat mengikuti lomba foto dengan kategori{" "}
            <strong>OTOMOTIF</strong>, hendaknya menambahkan hashtag
            #IDCC2021AUTOMOTIVE
            <br />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PhotoRuleTab;
