import React from "react";
import { Modal } from "react-bootstrap";

//CSS
// import "./ModalSuccess.scss";

const ModalSuccess = ({ show, handleClose, message }) => {
  return (
    <Modal
      backdrop="static"
      centered
      show={show}
      onHide={handleClose}
      size="sm"
      //   className="loading-modal"
    >
      <Modal.Body className="modal-success-container">
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>

        <h3>Berhasil</h3>
        <div className="wait-verif-txt">{message}</div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSuccess;
