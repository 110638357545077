import React from "react";
import { Row, Col } from "react-bootstrap";

const EditingPrize = () => {
  return (
    <div className="tab-contest px-4 pb-4">
      {/* HADIAH LOMBA */}
      <Row id="hadiah">
        <Col md={12}>
          <h3 className="mt-4 first-main-content-2">HADIAH LOMBA</h3>
          <h5 className="first-main-content-2">EDITING CONTEST</h5>

          <div className=" d-flex align-items-center mt-4">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/zve10.webp"
                alt="logo-sony"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 1</div>
              <div className="first-main-content-txt-bold-3">SONY ZV1-E10</div>
            </div>
          </div>

          <div className=" d-flex align-items-center mt-2">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/ad100pro.webp"
                alt="logo-ad100p"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 2</div>
              <div className="first-main-content-txt-bold-2 d-flex flex-wrap">
                <div className="mr-2">GODOX AD100 Pro</div>
                <div className="d-flex">
                  <div>+</div>
                  <div className="d-flex mx-2 flex-column align-items-center justify-content-center">
                    <div className="voucher-txt">Voucher</div>
                    <div className="doss-txt">DOSS</div>
                  </div>
                  <div>Rp 500.000</div>
                </div>
              </div>
            </div>
          </div>

          <div className=" d-flex align-items-center mt-2">
            <div className="price-img-container mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/sl60w.webp"
                alt="logo-ad100p"
                className="price-img-content"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold">Juara 3</div>
              <div className="first-main-content-txt-bold-2 d-flex flex-wrap">
                <div className="mr-2">GODOX SL60W</div>
                <div className="d-flex">
                  <div>+</div>
                  <div className="d-flex mx-2 flex-column align-items-center justify-content-center">
                    <div className="voucher-txt">Voucher</div>
                    <div className="doss-txt">DOSS</div>
                  </div>
                  <div>Rp 500.000</div>
                </div>
              </div>
            </div>
          </div>

          <Row className="">
            <Col md={6}>
              <div className="d-flex align-items-center juara-4 mt-3 w-100">
                <div className="price-img-container-2 mr-2">
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                    alt="bg-price"
                    className="price-img-bg-2"
                  />
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/260c.webp"
                    alt="logo-ad100p"
                    className="price-img-content-2"
                  />
                </div>
                <div>
                  <div className="first-main-content-txt-bold-4">Juara 4</div>
                  <div className="first-main-content-txt-bold-5">
                    GODOX LED 260C
                  </div>
                  <div className="first-main-content-txt-bold-5 d-flex">
                    <div>+</div>
                    <div className="d-flex mx-2 flex-column align-items-center justify-content-center">
                      <div className="voucher-txt">Voucher</div>
                      <div className="doss-txt">DOSS</div>
                    </div>
                    <div>500RB</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex align-items-center mt-3 w-100">
                <div className="price-img-container-2 mr-2">
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                    alt="bg-price"
                    className="price-img-bg-2"
                  />
                  <img
                    src="https://main.mobile.doss.co.id/storage/uploads/2021/12/120c.webp"
                    alt="logo-ad100p"
                    className="price-img-content-2"
                  />
                </div>
                <div>
                  <div className="first-main-content-txt-bold-4">Juara 5</div>
                  <div className="first-main-content-txt-bold-5">
                    GODOX 120C
                  </div>
                  <div className="first-main-content-txt-bold-5 d-flex">
                    <div>+</div>
                    <div className="d-flex mx-2 flex-column align-items-center justify-content-center">
                      <div className="voucher-txt">Voucher</div>
                      <div className="doss-txt">DOSS</div>
                    </div>
                    <div>500RB</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="d-flex align-items-center juara-favorit mt-3 w-100">
            <div className="price-img-container-2 mr-2">
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/bg-orange.webp"
                alt="bg-price"
                className="price-img-bg-2"
              />
              <img
                src="https://main.mobile.doss.co.id/storage/uploads/2021/12/voucher-doss.webp"
                alt="logo-ad100p"
                className="price-img-content-2"
              />
            </div>
            <div>
              <div className="first-main-content-txt-bold-4">
                Juara Favorit 1-5
              </div>
              <div className="first-main-content-txt-bold-2 d-flex">
                <div className="d-flex mx-2 flex-column align-items-center justify-content-center">
                  <div className="voucher-txt">Voucher</div>
                  <div className="doss-txt">DOSS</div>
                </div>{" "}
                <div>Ro 500.000</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EditingPrize;
