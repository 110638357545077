import React from "react";
import { Modal, Container, Row } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MdClose } from "react-icons/md";

const ModalZoomGallery = ({ show, handleClose, images, indexImg }) => {
  const convertStrip = (string) => {
    // const changed = string.replaceAll("_", "-");
    const changed = string.replace(/_/g, "-");
    return changed;
  };

  return (
    <Modal
      // dialogClassName="modal-90w"
      size="lg"
      centered
      show={show}
      onHide={handleClose}
    >
      <Container fluid className="position-relative">
        <MdClose
          size={20}
          color="#FFFF"
          className="close-btn-zoom"
          onClick={handleClose}
        />
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={200}
            defaultPositionY={100}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <TransformComponent>
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                    src={`https://main.mobile.doss.co.id/storage/uploads/2021/12/${convertStrip(
                      images[indexImg].thumburl
                    )}`}
                    alt="image-contest"
                  />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalZoomGallery;
