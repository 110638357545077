import React, { useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { AiFillGoogleCircle } from "react-icons/ai";
import GoogleLogin from "react-google-login";
import { useFormik } from "formik";
import * as yup from "yup";

import { API, setAuthToken } from "../configs/API";

const ModalRegister = ({
  show,
  handleClose,
  referralCode,
  toLogin,
  openOTP,
  setIsLogin,
  setUserInfo,
  OTPType,
  setOTPType,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    fullname: yup.string("Masukkan nama anda").required("Nama dibutuhkan"),
    email: yup
      .string("Masukkan email anda")
      .email("Masukkan format email yang benar")
      .required("Email dibutuhkan"),
    phone: yup
      .number("Masukkan no handphone anda")
      .required("No HP dibutuhkan"),
    password: yup
      .string("Masukkan password anda")
      .min(6, "Password harus minimal 6 karakter")
      .required("Password dibutuhkan"),
    password_confirmation: yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Kedua field password harus sama"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      referral_code: referralCode,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (body) => {
    try {
      setIsLoading(true);

      const res = await API.post("auth/register", body);

      if (res.data.error >= 400) {
        alert("Email sudah terdaftar");
      } else {
        setAuthToken(res.data.data.access_token.token);

        localStorage.setItem("token", res.data.data.access_token.token);

        if (res.data.data.user.activated_at == null) {
          alert("Mohon masukan kode OTP di dalam inbox hp kamu");
          localStorage.setItem("email", body.email);
          localStorage.setItem("phone", body.phone);

          // const res = await API.post("auth/otp/email/resend", {
          //   email: body.email,
          // });

          setOTPType("Phone");
          handleClose();
          openOTP();
        } else {
          localStorage.setItem("email", body.email);
          localStorage.setItem("phone", body.phone);
        }

        resetFormik();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert(error);
    }
  };

  const loginGoogle = async (response) => {
    try {
      const res = await API.post("auth/social/google", {
        id_token: response.tokenId,
      });

      if (res.data.data.user.phone == null) {
        // dispatch({
        //   type: "SET_PHONE",
        //   payload: true,
        // });
      }

      if (res.data.data.user.activated_at == null) {
        alert("Mohon masukan kode OTP di dalam inbox email kamu");
        localStorage.setItem("email", response.profileObj.email);

        handleClose();
        openOTP();
      }

      if (res.data.error == 0) {
        setAuthToken(res.data.data.access_token.token);
        localStorage.setItem("token", res.data.data.access_token.token);

        const res2 = await API.get("info/account?referral_code=GJIYNM0H");

        setIsLogin(true);
        setUserInfo(res.data.data.user);

        handleClose();

        window.location.href = `https://idcc.doss.co.id?code=${res2.data.data.code}`;
      }

      resetFormik();
    } catch (error) {
      console.log(error);
    }
  };

  const resetFormik = () => {
    formik.setValues({
      fullname: "",
      phone: "",
      email: "",
      password: "",
      password_confirmation: "",
      referral_code: "",
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body style={{ padding: "30px" }}>
        <div className="d-flex justify-content-between mb-4">
          <div className="modal-header-txt">DAFTAR</div>
          <MdClose
            color="#FFF"
            size={24}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{ color: "white" }}>Nama Lengkap</Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukan nama"
              name="fullname"
              value={formik.values.fullname}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label style={{ color: "white" }}>Alamat Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Masukan email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label style={{ color: "white" }}>Nomor HP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukan nomor handphone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label style={{ color: "white" }}>Kata Sandi</Form.Label>
            <Form.Control
              type="password"
              placeholder="Masukan kata sandi"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-5">
            <Form.Label style={{ color: "white" }}>
              Masukan Ulang Kata Sandi
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Masukan ulang kata sandi"
              name="password_confirmation"
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Button
            type="submit"
            className="main-btn"
            style={{ width: "100%" }}
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="mr-2"
              />
            )}
            <span>Daftar</span>
          </Button>

          {/* <div className="or-text mt-2">Atau</div> */}

          {/* <GoogleLogin
            clientId="563618122313-1a6m095n2filthn65a7g1e8rk26i607t.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                variant="contained"
                fullWidth
                className="mb-2 btn-google"
              >
                <AiFillGoogleCircle size={22} className="mr-2" />
                Masuk dengan Google
              </Button>
            )}
            onSuccess={loginGoogle}
            // onFailure={loginGoogle}
            cookiePolicy={"single_host_origin"}
          /> */}

          <div
            style={{
              textAlign: "center",
              fontSize: "80%",
              color: "#FFF",
            }}
            className="mt-3"
          >
            Sudah punya akun DOSS? Klik{" "}
            <span className="here-text" onClick={toLogin}>
              disini
            </span>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalRegister;
