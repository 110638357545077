import React, { useState, useContext } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Form,
  Alert,
  Button,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { MdClose } from "react-icons/md";
import { API } from "../configs/API";

const ModalForgotPassword = ({ show, handleClose, toRegister }) => {
  const [alertEmail, setAlertEmail] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string("Masukkan email anda")
      .email("Masukkan format email yang benar")
      .required("Email dibutuhkan"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmitReset(values);
    },
  });

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleSubmitReset = async (body) => {
    try {
      setIsLoading(true);
      setAlertEmail(false);

      const res = await API.post("auth/reset", body);

      if (res.data.error == 422) {
        setIsLoading(false);
        setAlertEmail(true);
      } else {
        setIsLoading(false);
        setAlertSuccess(true);
        await timeout(5000);
        formik.setValues({ email: "" });
        setAlertSuccess(false);
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body style={{ padding: "30px" }}>
        <div className="d-flex justify-content-between mb-4">
          <div className="modal-header-txt">RESET PASSWORD</div>
          <MdClose
            color="#FFF"
            size={24}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <Form onSubmit={formik.handleSubmit}>
          {/* Email */}
          <Form.Group className="mb-3">
            <Form.Label style={{ color: "white" }}>Masukan Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Masukan email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email && (
              <div style={{ color: "white" }}>{formik.errors.email}</div>
            )}
          </Form.Group>

          {alertEmail && (
            <Alert variant="danger" className="text-center">
              Email yang anda masukkan salah/tidak terdaftar
            </Alert>
          )}

          {alertSuccess && (
            <Alert variant="success" className="text-center">
              Cek inbox/kotak masuk email anda untuk proses selanjutnya
            </Alert>
          )}

          {/* Button Reset */}
          <Button
            type="submit"
            className="main-btn mb-2"
            style={{ width: "100%" }}
            disabled={isLoading || !formik.isValid}
          >
            {isLoading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="mr-2"
              />
            )}
            {!isLoading && <span>Reset Password</span>}
          </Button>

          <Form.Text style={{ textAlign: "center", color: "#FFFF" }}>
            Blum punya akun? Daftar{" "}
            <span className="here-text" onClick={() => toRegister()}>
              disini
            </span>
          </Form.Text>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalForgotPassword;
