import React, { useState } from "react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

//Configs
import { API, setAuthToken } from "../configs/API";

const ModalPhoneInput = ({ show, handleClose, setOTPType, openOTP }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    values.preventDefault();
    try {
      setLoading(true);

      let filtered = phoneNumber;
      if (phoneNumber.charAt(0) === "0") {
        filtered = filtered.substring(1);
      }
      if (phoneNumber.substring(0, 3) === "+62") {
        filtered = filtered.substring(3);
      }
      if (phoneNumber.substring(0, 2) === "62") {
        filtered = filtered.substring(2);
      }

      if (filtered.substring(0, 2) === "62") {
        filtered = filtered.substring(2);
      }

      if (filtered.charAt(0) === "0") {
        filtered = filtered.substring(1);
      }

      let fullPhone = `0${filtered}`;

      localStorage.setItem("phone", fullPhone);

      const body = { phone: fullPhone };
      const res = await API.post("auth/login/otp/request", body);

      if (res.data.error >= 400) {
        setLoading(false);
        alert(res.data.message);
      } else {
        setLoading(false);
        handleClose();

        setOTPType("Phone");
        openOTP();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <div className="d-flex justify-content-between mb-4">
          <div className="modal-header-txt">Masukan Nomor Handphone</div>
          <MdClose
            color="#FFF"
            size={24}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <form style={{ width: "100%" }} onSubmit={(e) => handleSubmit(e)}>
          {/* Phone */}
          <label style={{ color: "#FFFF", fontSize: "80%" }}>
            Masukkan Nomor Handphone aktif yang kamu daftarkan saat register.
          </label>
          <PhoneInput
            country={"id"}
            placeholder=""
            value={phoneNumber}
            disableDropdown={true}
            onChange={(phone) => setPhoneNumber(phone)}
            containerStyle={{ width: "100%", marginBottom: "10px" }}
          />

          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={phoneNumber === ""}
            className="modal-otp-btn text-center mt-2"
          >
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Request Kode OTP"
            )}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPhoneInput;
